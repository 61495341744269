import * as React from 'react';
import Button from '../../components/Button';
import content from '../../content/404.yaml';
import notFoundImage from '../../assets/404/not-found.svg';

const NotFoundPage = () => {
  return (
    <main data-testid="not-found-container" className="md-900px:pt-9 pt-7">
      <title>Not found</title>
      <div
        className="flex flex-column w-full mx-auto max-w-max md:flex-row md:text-left md:my-11 md:items-start md:justify-center"
        data-testid="hero-container"
      >
        <div className="flex flex-col w-full md:items-start md:w-auto">
          <h1
            className="font-bold text-2xl mt-7 text-center tracking-tighter md:text-4xl pl-0 md:mt-0 md:font-extrabold lg:tracking-normal"
            data-testid="title-line1"
          >
            {content.header.line1}
          </h1>
          <h1
            className="font-bold text-2xl text-center tracking-tighter md:text-4xl pl-0 md:mt-0 md:font-extrabold lg:tracking-normal"
            data-testid="title-line2"
          >
            {content.header.line2}
          </h1>
          <p
            className="mt-3 mb-7 text-center tracking-tighter md:mt-6 md:mb-0 md:text-lg md:font-semibold lg:tracking-normal"
            data-testid="hero-caption"
          >
            {content.subheader}
          </p>
          <img
            src={notFoundImage}
            alt={content.image.alt}
            className="mx-auto md:hidden"
          />
          <div className="mt-7 mb-10 flex justify-center">
            <Button
              type="inverse"
              text={content.cta.join}
              className="leading-3 w-140px lg:w-200px"
              to="/join"
            />
            <Button
              text={content.cta.home}
              className="leading-3 ml-2.5 w-140px lg:w-200px"
              to="/home"
            />
          </div>
        </div>
        <div className="">
          <img
            src={notFoundImage}
            alt={content.image.alt}
            className="mx-auto hidden md:inline w-345px md:ml-4 lg:ml-8"
          />
        </div>
      </div>
    </main>
  );
};

export default NotFoundPage;
